//component
// import CompanyInfoVideo from "../components/Company_info_video";
import CompanyInfo from "../components/Company_info";
//css
import styles from "../css/Company.module.css";
// assets
import CompanyInfoVod from "../components/Company_info_video";

export default function CompanyBottom() {
  const cInfo = [
    {
      title: "BNVs News",
      bodies: [
        { key: 1, body: "2023 온라인 개인맞춤 코딩튜터 플랫폼 특허출원" },
        { key: 2, body: "2024 AI바우처 솔루션 인증 및 공급기업 선정" },
        { key: 3, body: "2024 한국데이터산업진흥원 데이터 가공기업 선정" },
      ],
    },
    {
      title: "수상 이력 & 프로젝트",
      bodies: [
        {
          key: 1,
          body: "긴급차량 골든타임 확보 AI 관제 솔루션",
          win: "2022 디지털 해커톤 최우수상",
          video:"5TVzlemRwvM"
        },
        {
          key: 2,
          body: "AI 기반 최적 하늘길 구축시스템",
          win: "2022 국토교통부 주관 데이터 활용 경진대회 우수상",
          video:"XbdkQDdfL98"
        },
        {
          key: 3,
          body: "데이터 기반 서울시 지하철역 어린이집 입지 선정 ",
          win: "2022 데이터 안심구역 데이터 활용 경진대회 최우수상",
          video:"TEuVGVeGm_g"
        },
        {
          key: 4,
          body: "대규모 한국어 도서말뭉치 데이터 활용 인공지능 서비스",
          win: "2021 과학기술정보통신부, NIA 주관 AI서비스 공모전 최우수상",
          video:"ddHfYXKoEHI"
        },
        {
          key: 5,
          body: "소아흉부 폐질환 진단 및 분류 ",
          win: "2021 고려대학교 주관 AI모델 개발 경진대회 2위",
          video:"3gn40VTXiCk"
        },
        { key: 6, body: "AI기반 관광지 개선 솔루션",video:"zk4ZPOgnNNM" },
        { key: 7, body: "구매감소 고객 예측 모델 활용 솔루션",video:"wj2r04LIaDo" },
        { key: 8, body: "반도체 결함 탐지 AI 솔루션",video:"uCAcmUrEIwg" },
        { key: 9, body: "AI 기반 뉴스 요약 챗봇 서비스",video:"NdtA14SLUCs" },
      ],
    },
    {
      title: "Dfuture 교육이력",
      bodies: [
        { key: 1, body: "20개 이상의 초·중·고 학교에 인공지능 강의 제공" },
        {
          key: 2,
          body: "3000명 이상의 학생에게 Dfuture 교육 제공",
        },
        {
          key: 3,
          body: "K-Digital Training 인공지능 프로젝트 기획 및 실전 멘토링",
        },
      ],
    },
  ];

  return (
    <section className={styles.CompanyInfo}>
      <CompanyInfo
        ment={cInfo[0].bodies}
        subject={cInfo[0].title}
      />

      <CompanyInfoVod
        ment={cInfo[1].bodies}
        subject={cInfo[1].title}
      />

      <CompanyInfo ment={cInfo[2].bodies} subject={cInfo[2].title} />
    </section>
  );
}
